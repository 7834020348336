import 'regenerator-runtime/runtime';
import {documentReady} from "./document-ready";
import {importRouteJs} from "./routes";
import {setUpLocalization} from './locales';
import {handleCallback} from './oauth-callback';
import {setupLogoutListener} from './header'
import './polyfills/closest';

documentReady.then(() => {
  setUpLocalization();
  handleCallback();
  setupLogoutListener();

  const getRoute = () => {
    const pathRegex = /^path-(.+?)_(.+)$/;
    const classes = document.body.classList.values();

    for (let aClass of classes) {
      const result = aClass.match(pathRegex);

      if (result) {
        const [ _, controller, action ] = result;

        return {controller, action};
      }
    }
  };

  const {controller, action} = getRoute();

  // Import the js for this controller/action, if it exists.
  importRouteJs(controller, action)
    .catch(() => 'No file found, that\'s ok.');
});

/**
 * This is a fix for dialogs that we have issues with opening in IOS,
 *
 */
document.addEventListener('iron-overlay-opened', patchOverlay);
document.addEventListener('iron-overlay-closed', removeOverlay);

function patchOverlay(e) {
  // we dont do this for places that dont have composedPath (Ie, Edge)
  if(typeof e.composedPath() !== "object"){
    return;
  }

  let path = e.composedPath();
  let dialog = path[0];
  if (dialog.withBackdrop) {
    dialog.parentNode.insertBefore(dialog.backdropElement, dialog);
  }
}

function removeOverlay(e){
  // we dont do this for places that dont have composedPath (Ie, Edge)
  if(typeof e.composedPath() !== "object"){
    return;
  }

  let path = e.composedPath();
  let dialog = path[0];
  if (dialog.withBackdrop) {
    dialog.parentNode.removeChild(dialog.backdropElement);
  }
}
