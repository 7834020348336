let header;

export const getHeader = () => {
  if (!header) {
    header = document.querySelector('cbar-header');
  }

  return header;
};

export const setupLogoutListener = () => {
  header = getHeader();

  // for pages like login, dont worry about this.
  if(!header) {
    return;
  }

  header.addEventListener('logout', (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location.replace(window.location.origin + '/user/logout');
  });
};
