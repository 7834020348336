import {WebStorageStateStore, UserManager} from 'oidc-client-ts';
import {makeRequest} from "./fetch";

let clientPromise = null;

// this function will ensure we dont call this multiple times per page and can rely on this once
const getClientId = async () => {
  if(!clientPromise){
    clientPromise = new Promise(async (res, rej) => {
      const clientResponse = await makeRequest('/profile/ajaxgetclient');

      res(clientResponse.client_id);
    });

  }

  return clientPromise;
}

export const handleCallback = async () => {
  // gets generated by oidc
  let query = window.location.search;

  //TODO: we need another way to handle this, for now its okay
  const scope = "openid profile email phone address social"

  const authority = window.location.origin;
  const client_id = await getClientId();
  const redirect_uri = window.location.href.split('?')[0];

  const userStore = new WebStorageStateStore({store: window.localStorage});

  let userManager = new UserManager({
    userStore,
    authority,
    client_id,
    scope,
    redirect_uri,
    loadUserInfo: true,
    metadata: {
      token_endpoint: `${authority}/oauth/token`,
      userinfo_endpoint: `${authority}/userinfo`,
      authorization_endpoint: `${authority}/oauth/authorize`
    }
  });

  const existingUser = await userManager.getUser();

  if(existingUser && existingUser.expired){
    await userManager.signinSilent();
    return;
  }

  // ensure this doesnt happen for routes that are public
  const isPublicRoute = location.href.indexOf('user') !== -1 || location.href.indexOf('token') !== -1;
  const notValidOidcCallback = !query || query.indexOf('code') === -1 || query.indexOf('id_token') === -1;

  // the user will need to relog here
  if(notValidOidcCallback && !existingUser && !isPublicRoute) promptUserRelog();
  if(notValidOidcCallback) return;

  let urlParams = new URLSearchParams(query);

  let propertiesObject = {
    code: urlParams.get('code'),
    id_token: urlParams.get('id_token'),
    state: urlParams.get('state')
  }

  const expires_at = getTimeNowInSeconds() + propertiesObject.expires_in;

  const user = {
    profile: {},
    client_id,
    expires_at,
    authority,
    id: propertiesObject.state,
    state: {
      ...propertiesObject
    },
    redirect_uri,
  };

  //TODO: this is hacky, as we need it here to be handled by the signinredirectcallback function, if this isnt put in, the function throws
  localStorage.setItem(`oidc.${propertiesObject.state}`, JSON.stringify(user));

  await userManager.signinRedirectCallback();

  removeHash();
};

function removeHash() {
  history.pushState("", document.title, window.location.pathname);
}

function getTimeNowInSeconds() {
  return Number((Date.now() / 1000).toFixed(0));
}

function promptUserRelog(){
  location.href = "/user/logout?from_jwt=true";
}

export async function getUser(){
  let userData = await localStorage.getItem(`oidc.user:${window.location.origin}:${await getClientId()}`);
  return JSON.parse(userData);
}