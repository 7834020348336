import axios from 'axios';
import qs from 'qs';

export const makeRequest = (url, params = {}, method = 'GET', jwt) => {
  const encode = encodeURIComponent;
  let options = {method, headers: {}};

  if(jwt){
    options.headers.Authorization = `Bearer ${jwt}`;
  }

  if (method === 'GET') {
    // Create a querystring
    url += '?' + Object.keys(params)
      .map(key => `${encode(key)}=${encode(params[key])}`)
      .join('&');
  } else {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(params);
  }

  return fetch(url, options).then(async (response) => {
    if (!response.ok) {
      throw new Error(`Fetch failed: ${response.statusText}`);
    }
    return response.json();
  });
};

export const makeAxiosRequest = (endpoint, method = 'GET', params = {}, headers = {}) => {
  let url = endpoint;

  // Convert params object to querystring
  params = qs.stringify(params);

  const options = {url, method, headers};
  if (method === 'GET') {
    options.params = params;
  } else {
    options.data = params;
  }

  return axios(options);
}

export const makeRequestWithJwt = jwt => {
  return (url, params = {}, method = 'GET') => {
    return makeRequest(url, params, method, jwt);
  }
};
