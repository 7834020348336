import {getHeader} from "./header";

export function setUpLocalization() {
  window.customElements.whenDefined('cbar-header').then(() => {

  const header = getHeader();
  if (!header) {
    // No header, no localization. Terrible, but c'est la vie.
    return;
  }

  let language = getCurrentLanguage();

  // Set the language on the header (since it's separate from our settings page)
  // and also the dom-bind inside the settings page.
  header.setLanguage(language);

  setTimeout(() => {
    localizePage(header.localize);
  }, 500);
  });
}

/**
 * function that starts the update of all elements needed to be localized on the page
 */
export function localizePage(localizeFn) {
  const sideNav = document.querySelector("div[slot='drawer-contents']");
  const appList = document.querySelector("div[slot='apps']");

  const sidenavItems = sideNav ? document.querySelectorAll("div[slot='drawer-contents'] cbar-list-item") : [];

  localizeItems(localizeFn, sidenavItems, 'localizeName');

  //get all the app switcher items
  const apps = appList ? appList.children : [];
  localizeItems(localizeFn, apps, 'title');

  localizeHeaders(localizeFn);
}

export function getCurrentLanguage() {
  const savedLanguageEle = document.querySelector('#user-language');
  if (savedLanguageEle && !!savedLanguageEle.value.trim()) {
    return savedLanguageEle.value.trim();
  }

  // Retrieve from the cbar-header (it pulls the language from the navigator)
  return getHeader().language;
}

function localizeItems(localizeFn, items, titleProp) {
  for (let item of items) {
    item.itemTitle = localizeFn(item.dataset[titleProp]) || item.dataset[titleProp];
  }
}

/**
 * localizing any headers on the page
 */
function localizeHeaders(localizeFn) {
  const headers = document.querySelectorAll('.localized-header');

  headers.forEach(header => {
    const defaultText = header.dataset.value;

    var splitHeader = defaultText.split(/\s+/);
    var localized = localizeFn(defaultText);

    if (localized && localized !== defaultText) {
      header.innerHTML = localized;
    } else {
      if (splitHeader.length > 1) {
        const words = [];

        splitHeader.forEach(word => {
          let localizedWord = localizeFn(word);

          if (localizedWord === word) {
            // No change, try the capitalised word
            localizedWord = localizeCapitalised(word, localizeFn);
          }

          words.push(localizedWord);
        });

        header.innerHTML = words.join(' ');
      } else if (!header.innerHTML) {
        header.innerHTML = defaultText;
      }
    }
  });
}

function capitalise(txt) {
  return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
}

function localizeCapitalised(word, localizeFn) {
  const capitalised = capitalise(word);

  const result = localizeFn(capitalised);
  if (result.toLowerCase() === word.toLowerCase()) {
    return word;
  }

  return result;
}

/**
 * updates the user language and persists to LDAP
 * @param language
 */
export function updateLanguage(language) {
  var formData = new FormData();

  formData.append('language', language);

  $.ajax({
    method: "POST",
    data: formData,
    processData: false,
    contentType: false,
    url: "/profile/ajaxeditlanguage",
  });
}
